




















































































































































































































































































































































































































































































































#signature {
  margin: auto;
  margin-top: 25px;
  margin-bottom: 15px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: double 3px transparent;
  border-radius: 5px;
}
